/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/uikit@3.5.4/dist/js/uikit.min.js
 * - /npm/uikit@3.5.4/dist/js/uikit-icons.min.js
 * - /npm/underscore@1.10.2/underscore.min.js
 * - /npm/jquery-validation@1.19.3/dist/jquery.validate.min.js
 * - /npm/formstone@1.4.16-1/dist/js/core.js
 * - /npm/formstone@1.4.16-1/dist/js/dropdown.js
 * - /npm/formstone@1.4.16-1/dist/js/scrollbar.js
 * - /npm/formstone@1.4.16-1/dist/js/touch.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
